import { Button, Card, CardContent, Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const Cart = () => {
  const cartdata = useSelector((state) => state.pcartReducer.cart);
  return (
    <Grid container spacing={2} align="center" sx={{ padding: "10px" }}>
      <Grid item xs={11} align="right">
        <Link to="/">
          {" "}
          <Button variant="contained">Back</Button>
        </Link>{" "}
      </Grid>
      {cartdata.map((item) => (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xl={2} lg={2} md={2} sm={2} sx={6}>
                  <img
                    src={item.image}
                    style={{ width: 100, height: 100 }}
                    alt=""
                  />
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} sx={6}>
                  <h2> Rs. {item.price} </h2>
                  <p> {item.title.slice(0, 20) + "..."} </p>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} sx={12}>
                  {" "}
                  <p> {item.description} </p>{" "}
                </Grid>
                <Grid item xs={2}>
                  <Button variant="contained" fullWidth color="error">
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
