import { Button, Card, CardContent, Grid, Rating } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

export const ProductList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const getApi = async () => {
    const result = await axios.get("https://fakestoreapi.com/products");
    setData(result.data);
  };

  const handleaddCart = (item) => {
    const type = "ADD_CART";
    const payload = item;
    const action = { type, payload };
    dispatch(action);
  };

  const handleDetail = (item) => {
    const type = "P_DETAIL";
    const payload = item;
    const action = { type, payload };
    dispatch(action);
    navigate("/proddetail");
  };

  useEffect(() => {
    getApi();
  }, []);

  return (
    <div>
      <Grid container spacing={2} sx={{ padding: "15px", textAlign: "center" }}>
        {data.map((item) => {
          return (
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
              <Card>
                <CardContent>
                  <img
                    onClick={() => handleDetail(item)}
                    src={item.image}
                    style={{ width: "250px", height: "250px" }}
                    alt=""
                  />
                  <h1>Rs. {item.price} </h1>
                  <h3> {item.title.slice(0, 20) + "...."} </h3>
                  <Rating value={item.rating.rate} />
                  <span> ( {item.rating.count} ) </span>
                  <br />
                  <br />
                  <Button
                    onClick={() => handleaddCart(item)}
                    startIcon={<ShoppingCartIcon />}
                    variant="contained"
                    fullWidth
                  >
                    ADD To Cart
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
