import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Button,
  Badge,
  Typography,
} from "@mui/material";
import { navdata } from "./data";
import { Link } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useSelector } from "react-redux";

export const Nav = () => {
  const cartdata = useSelector((state) => state.pcartReducer.cart);
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} align="right">
          {/* {navdata.map((item) => (
            <Grid item xs={2}>
              <Link to={item.path}>
                <Button variant="contained" fullWidth>
                  {item.title}
                </Button>
              </Link>
            </Grid>
          ))} */}
          <Grid item xs={6}>
            <Typography variant="h4">PickupBiz</Typography>
          </Grid>
          <Grid item xs={5}>
            <Link to="/cart">
              <Badge badgeContent={cartdata && cartdata.length}>
                <ShoppingCartIcon />
              </Badge>
            </Link>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
