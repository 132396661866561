import { Button, Grid, Rating } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import BoltIcon from "@mui/icons-material/Bolt";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

export const ProductDetail = () => {
  const navigate = useNavigate();
  const selectdata = useSelector((state) => state.pdetailReducer.details);

  useEffect(() => {
    if (selectdata.length === 0) {
      navigate("/prodlist");
    }
  }, []);

  return (
    <div>
      <Grid container spacing={2} sx={{ padding: "20px" }}>
        <Grid item xs={12} align="right">
          <Link to="/">
            {" "}
            <Button variant="contained">Back</Button>
          </Link>{" "}
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xl={4} lg={4} md={6} sm={6} sx={12}>
          <img
            src={selectdata.image}
            style={{ width: 350, height: 350 }}
            alt=""
          />
          <Button
            sx={{ height: 55, width: 160 }}
            startIcon={<ShoppingCartIcon />}
            variant="contained"
          >
            ADD Cart
          </Button>
          <Button
            sx={{ height: 55, width: 160, margin: 2 }}
            startIcon={<BoltIcon />}
            variant="contained"
            color="warning"
          >
            buy
          </Button>
        </Grid>
        <Grid
          item
          xl={7}
          lg={7}
          md={7}
          sm={12}
          xs={12}
          sx={{ padding: "50px" }}
        >
          <h3> {selectdata.title} </h3>
          <h1> Rs. {selectdata.price} </h1>
          <p> {selectdata.description} </p>
        </Grid>
      </Grid>
    </div>
  );
};
