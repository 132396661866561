import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Nav } from "./Nav";
import { Home } from "./Home";
import { AddProduct } from "./AddProduct";
import { Products } from "./Products";
import { ProductList } from "./ProductList";
import { ProductDetail } from "./ProductDetail";
import { Cart } from "./Cart";

export const Landing = () => {
  return (
    <BrowserRouter>
      <Nav />
      <br />
      <br />
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/addprod" element={<AddProduct />} />
        <Route path="/prods" element={<Products />} />
        <Route path="/" element={<ProductList />} />
        <Route path="/proddetail" element={<ProductDetail />} />
        <Route path="/cart" element={<Cart />} />
      </Routes>
    </BrowserRouter>
  );
};
